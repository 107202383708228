import React from 'react';
import PropTypes from 'prop-types';
import styledModule from 'helpers/styledModule';
import ProviderLogo from 'components/ProviderLogo';
import ItemMeta from 'modules/item/components/ItemMeta';
import resetStyles from 'higher-order-components/resetStyles';
import ContentBody from '../ContentBody';
import CSS from './Reader.scss';

const StyledProviderLogo = styledModule(ProviderLogo)(CSS.providerLogo);
const StyledItemMetaContainer = styledModule(ItemMeta)(CSS.itemMeta);

const Reader = ({ content, manifest }) => (
  <div>
    <StyledProviderLogo provider={content.provider} />
    <StyledItemMetaContainer date={content.date} length={manifest.length} />
    <ContentBody content={content} />
  </div>
);

Reader.propTypes = {
  content: PropTypes.object.isRequired,
  manifest: PropTypes.object.isRequired,
};

export default resetStyles(Reader);
