/* eslint-disable class-methods-use-this */

import alt from 'instances/altInstance';
import * as SharingManager from 'managers/sharing';

class ReadForFreeActions {
  getSharedItemSuccess = res => res;

  getSharedItemError = error => error;

  getSharedItem(token) {
    SharingManager.getSharedItem(token)
      .then(res => this.getSharedItemSuccess(res))
      .catch(error => this.getSharedItemError(error));

    return { token };
  }
}

export default alt.createActions(ReadForFreeActions);
