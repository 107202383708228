// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".section-3qBf5{display:flex;flex-direction:column;margin:0 auto 32px;background-color:#fff;max-width:600px;width:100%;padding:16px;box-shadow:0 4px 4px rgba(0,0,0,0.25);border-radius:6px}@media (min-width: 600px){.section-3qBf5{padding:56px}}.providerContainer-2xgdw{text-align:center;background:none !important}.providerLogo-19CaM{max-width:160px;max-height:30px;margin:0 auto 20px}.itemTitle-2m98J{max-width:700px;margin:0 auto 30px !important;text-align:center !important;padding:0 !important}.content-3DDDq{max-width:422px;margin:0 auto}.title-1hoX3{font-family:\"GT-Walsheim\",sans-serif;font-weight:600;color:#000;letter-spacing:-0.05em;font-size:30px;line-height:32px;margin:0 auto}@media (min-width: 600px){.title-1hoX3{font-size:44px;line-height:46px}}.body-1_CNG{font-family:\"GT-Walsheim\",sans-serif;color:#474747;font-size:15px;letter-spacing:-0.02em;opacity:0.9;margin:0 auto 21px;text-align:center}@media (min-width: 600px){.body-1_CNG{font-size:20px}}.uspWrapper-2atjp{max-width:315px;margin:0 auto}.usp-cT_sq{display:block;font-family:\"GT-Walsheim\",sans-serif;font-size:15px;line-height:15px;letter-spacing:-0.02em;color:#474747;margin-bottom:8px;text-align:left}.checkmark-3CXsV{margin-right:8px}.cta-aSd2v{margin:22px auto 8px}.tellMeMore-_R0lD{margin:0 auto;width:250px;display:unset}\n", ""]);
// Exports
exports.locals = {
	"section": "section-3qBf5",
	"providerContainer": "providerContainer-2xgdw",
	"providerLogo": "providerLogo-19CaM",
	"itemTitle": "itemTitle-2m98J",
	"content": "content-3DDDq",
	"title": "title-1hoX3",
	"body": "body-1_CNG",
	"uspWrapper": "uspWrapper-2atjp",
	"usp": "usp-cT_sq",
	"checkmark": "checkmark-3CXsV",
	"cta": "cta-aSd2v",
	"tellMeMore": "tellMeMore-_R0lD"
};
module.exports = exports;
