// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".link-2sP_D{font-family:\"GT-Walsheim\",sans-serif;font-size:14px;text-align:center;letter-spacing:-0.02em;color:#979797;text-decoration:underline}\n", ""]);
// Exports
exports.locals = {
	"link": "link-2sP_D"
};
module.exports = exports;
