import React from 'react';
import { object, string, shape } from 'prop-types';
import styledModule from 'helpers/styledModule';
import Intro from './Intro';
import Reader from './Reader';
import Outro from './Outro';
import Navigation from './Navigation';
import CSS from './ShareReader.scss';

const Container = styledModule.div(CSS.container);

const ShareReader = ({ content, manifest, sharedBy }) => {
  const { id } = manifest;

  return (
    <Container>
      <Navigation itemId={id} sharedBy={sharedBy} />
      <Intro sharedBy={sharedBy} itemId={id} />
      <Reader content={content} manifest={manifest} />
      <Outro sharedBy={sharedBy} itemId={id} />
    </Container>
  );
};

ShareReader.propTypes = {
  content: object.isRequired,
  manifest: object.isRequired,
  sharedBy: shape({
    name: string,
    uuid: string.isRequired,
  }).isRequired,
};

export default ShareReader;
