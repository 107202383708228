// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-VhaFg{display:flex;justify-content:space-between;height:56px;padding-right:1px;z-index:100}@media (min-width: 600px){.container-VhaFg{min-height:66px}}.logo-3FeU1{position:relative;padding-left:16px}.buttons-3lOzZ{padding-right:16px;display:flex;align-self:center}.login-16FiD{font-family:\"GT-Walsheim\",sans-serif;font-size:14px;color:#fff;margin-right:0;line-height:36px;text-decoration:none}.login-16FiD:hover{text-decoration:underline}@media (min-width: 600px){.login-16FiD{line-height:40px;margin-right:16px}}.signup-BC7WH{display:none}@media (min-width: 600px){.signup-BC7WH{display:block;padding:11px 20px;font-weight:500}}\n", ""]);
// Exports
exports.locals = {
	"container": "container-VhaFg",
	"logo": "logo-3FeU1",
	"buttons": "buttons-3lOzZ",
	"login": "login-16FiD",
	"signup": "signup-BC7WH"
};
module.exports = exports;
