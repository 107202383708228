/* eslint-disable react/forbid-prop-types */
import React from 'react';
import R from 'ramda';
import { string, shape, bool, object } from 'prop-types';
import { STATUS_INITIAL, STATUS_PENDING, STATUS_OK, STATUS_ERROR } from 'app-constants';
import { setPropTypes, compose, branch, lifecycle } from '@blendle/recompose';
import altConnect from 'higher-order-components/altConnect';
import redirect from 'higher-order-components/redirect';
import Error from 'components/Application/Error';
import AuthStore from 'stores/AuthStore';
import useEventOnMount from 'hooks/useEventOnMount';
import usePageEventOnMount from 'hooks/usePageEventOnMount';
import ReadForFreeActions from '../actions/ReadForFreeActions';
import ShareReader from '../components/ShareReader';
import ShareLimit from '../components/ShareLimit';
import Loading from '../components/Loading';
import ReadForFreeStore from '../stores/ReadForFreeStore';

const isLimitReachedError = R.compose(R.equals(429), R.prop('status'));

const ShareReaderWrapper = ({ content, manifest, sharedBy, isLimitReached }) => {
  const properties = {
    item_id: manifest.id,
    provider_id: manifest.provider.id,
    referrer_user_uuid: sharedBy.uuid,
  };

  useEventOnMount(
    isLimitReached ? 'Share Free Article: Limit Reached Shown' : 'Free Share Article: Reader Shown',
    properties,
  );
  usePageEventOnMount({
    pageName: isLimitReached ? 'Share Free Article: Limit Reached' : 'Free Share Article: Reader',
    properties,
  });

  return isLimitReached ? (
    <ShareLimit manifest={manifest} sharedBy={sharedBy} />
  ) : (
    <ShareReader manifest={manifest} content={content} sharedBy={sharedBy} />
  );
};

ShareReaderWrapper.propTypes = {
  isLimitReached: bool.isRequired,
  sharedBy: shape({
    name: string,
    uuid: string.isRequired,
  }).isRequired,
  content: object.isRequired,
  manifest: object.isRequired,
};

const ShareReaderContainer = ({ hasError, isLoading, ...rest }) => {
  if (isLoading) {
    return <Loading />;
  }

  if (hasError) {
    return <Error />;
  }

  return <ShareReaderWrapper {...rest} />;
};

ShareReaderContainer.propTypes = {
  isLoading: bool.isRequired,
  hasError: bool.isRequired,
  isLimitReached: bool.isRequired,
  sharedBy: shape({
    name: string,
    uuid: string.isRequired,
  }),
  content: object,
  manifest: object,
};

ShareReaderContainer.defaultProps = {
  content: undefined,
  manifest: undefined,
  sharedBy: undefined,
};

function mapStateToProps({ readForFreeState, authState }) {
  const { status, content, manifest, sharedBy, error } = readForFreeState;
  const isLoggedIn = Boolean(authState.user);

  const defaultProps = {
    isLoading: false,
    hasError: false,
    isLimitReached: false,
    redirectTo: undefined,
  };

  switch (status) {
    case STATUS_INITIAL:
    case STATUS_PENDING:
      return {
        ...defaultProps,
        isLoading: true,
      };
    case STATUS_ERROR:
      if (isLimitReachedError(error) && manifest) {
        return {
          ...defaultProps,
          isLimitReached: true,
          manifest,
          sharedBy,
          redirectTo: isLoggedIn ? `/item/${manifest.id}` : undefined,
        };
      }

      return {
        ...defaultProps,
        hasError: true,
      };
    case STATUS_OK:
      return {
        ...defaultProps,
        content,
        manifest,
        sharedBy,
        redirectTo: isLoggedIn ? `/item/${manifest.id}` : undefined,
      };
    default:
      return {
        ...defaultProps,
        isLoading: true,
      };
  }
}

mapStateToProps.stores = { ReadForFreeStore, AuthStore };

const enhance = compose(
  setPropTypes({ params: shape({ token: string.isRequired }).isRequired }),
  altConnect(mapStateToProps),
  lifecycle({
    componentDidMount() {
      const { params } = this.props;
      ReadForFreeActions.getSharedItem.defer(params.token);
    },
  }),
  branch(({ redirectTo }) => redirectTo, redirect({ replace: true, renderNothing: true })),
);

export default enhance(ShareReaderContainer);
