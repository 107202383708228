// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-xWax7{position:fixed;top:0;left:0;right:0;background-color:#fff;display:flex;justify-content:space-between;height:56px;box-shadow:0 1px 7px rgba(0,0,0,0.1);z-index:100}@media (min-width: 600px){.container-xWax7{min-height:66px}}.logo-2ItmD{position:relative;padding-left:16px}.buttons-2hOi8{padding-right:16px;display:flex;align-self:center}.login-2qn_j{font-family:\"GT-Walsheim\",sans-serif;font-size:15px;color:#5b5b5b;margin-right:16px;line-height:36px;text-decoration:none}.login-2qn_j:hover{color:#ff1060}@media (min-width: 600px){.login-2qn_j{line-height:40px}}@keyframes fadeIn-1bRtw{from{opacity:0}to{opacity:1}}.signup-1GHLf{display:none}@media (min-width: 600px){.signup-1GHLf{display:block;animation:fadeIn-1bRtw 0.5s ease-in forwards}}\n", ""]);
// Exports
exports.locals = {
	"container": "container-xWax7",
	"logo": "logo-2ItmD",
	"buttons": "buttons-2hOi8",
	"login": "login-2qn_j",
	"signup": "signup-1GHLf",
	"fadeIn": "fadeIn-1bRtw"
};
module.exports = exports;
