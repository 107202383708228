// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-3VUa4{background-color:#fff;padding-top:55px}@media (min-width: 600px){.container-3VUa4{padding-top:66px}}\n", ""]);
// Exports
exports.locals = {
	"container": "container-3VUa4"
};
module.exports = exports;
