import { STATUS_INITIAL, STATUS_PENDING, STATUS_OK, STATUS_ERROR } from 'app-constants';
import R from 'ramda';
import alt from 'instances/altInstance';
import ReadForFreeActions from '../actions/ReadForFreeActions';

const contentFromResponse = R.path(['data', '_embedded', 'content']);
const manifestFromResponse = R.path(['data', '_embedded', 'manifest']);
const sharedByFromResponse = R.path(['data', 'shared_by']);

class ReadForFreeStore {
  constructor() {
    this.bindActions(ReadForFreeActions);

    this.state = {
      status: STATUS_INITIAL,
      content: null,
      manifest: null,
      sharedBy: null,
      error: null,
    };
  }

  onGetSharedItem() {
    this.setState({
      status: STATUS_PENDING,
      content: null,
      manifest: null,
      sharedBy: null,
      error: null,
    });
  }

  onGetSharedItemSuccess(res) {
    const content = contentFromResponse(res);
    const manifest = manifestFromResponse(res);
    const sharedBy = sharedByFromResponse(res);

    this.setState({
      status: STATUS_OK,
      content,
      manifest,
      sharedBy,
      error: null,
    });
  }

  onGetSharedItemError(error) {
    const manifest = manifestFromResponse(error);
    const sharedBy = sharedByFromResponse(error);

    this.setState({
      status: STATUS_ERROR,
      content: null,
      manifest,
      sharedBy,
      error,
    });
  }
}

export default alt.createStore(ReadForFreeStore, 'ReadForFreeStore');
