import React from 'react';
import PropTypes from 'prop-types';
import styledModule from 'helpers/styledModule';
import Link from 'components/Link';
import CSS from './TellMeMore.scss';

const StyledLink = styledModule(Link)(CSS.link);

const TellMeMore = ({ className }) => (
  <div>
    <StyledLink href="/getpremium" className={className}>
      Vertel me eerst iets meer over Blendle
    </StyledLink>
  </div>
);

TellMeMore.propTypes = {
  className: PropTypes.string,
};

TellMeMore.defaultProps = {
  className: '',
};

export default TellMeMore;
