import React from 'react';
import styledModule from 'helpers/styledModule';
import Loading from 'components/Loading';
import CSS from './Loading.scss';

const Container = styledModule.div(CSS.container);

const LoadingComponent = () => (
  <Container>
    <Loading center />
  </Container>
);

export default LoadingComponent;
