import React from 'react';
import { string, shape } from 'prop-types';
import LogoLink from 'components/navigation/LogoLink';
import styledModule from 'helpers/styledModule';
import Link from 'components/Link';
import { INTERNAL_LOCATION } from 'app-constants';
import CSS from './Navigation.scss';
import SignupCta from '../../SignupCta';

const CTA_SCROLL_THRESHOLD = 260;

const Container = styledModule.div(CSS.container);
const Buttons = styledModule.div(CSS.buttons);
const Login = styledModule(Link)(CSS.login);
const Signup = styledModule(SignupCta)(CSS.signup);

class Navigation extends React.Component {
  static propTypes = {
    itemId: string.isRequired,
    sharedBy: shape({
      name: string,
      uuid: string.isRequired,
    }).isRequired,
  };

  state = {
    pageHasScrolledPastCtaThreshold: false,
    animationFrameRequested: false,
  };

  componentDidMount() {
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { animationFrameRequested } = this.state;

    if (!animationFrameRequested) {
      this.setState({ animationFrameRequested: true });

      window.requestAnimationFrame(() => {
        const pageHasScrolledPastCtaThreshold = window.scrollY > CTA_SCROLL_THRESHOLD;

        this.setState({
          pageHasScrolledPastCtaThreshold,
          animationFrameRequested: false,
        });
      });
    }
  };

  render() {
    const { pageHasScrolledPastCtaThreshold } = this.state;
    const { itemId, sharedBy } = this.props;

    return (
      <Container>
        <LogoLink className={CSS.logo} width={100} height={26} />
        <Buttons>
          <Login href={`/getpremium/item/${itemId}/login`}>Inloggen</Login>
          {pageHasScrolledPastCtaThreshold && (
            <Signup
              itemId={itemId}
              internalLocation={INTERNAL_LOCATION.READ_FOR_FREE}
              sharedBy={sharedBy}
            />
          )}
        </Buttons>
      </Container>
    );
  }
}

export default Navigation;
