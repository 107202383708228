// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".title-EhWOQ{font-family:\"GT-Walsheim\",sans-serif;color:#fff;letter-spacing:-0.05em;font-size:30px;line-height:32px;margin:4px auto 15px}@media (min-width: 600px){.title-EhWOQ{font-size:44px;line-height:46px;margin:49px auto 16px}}.body-gq8k5{max-width:660px;font-family:\"GT-Walsheim\",sans-serif;color:#fff;font-size:20px;letter-spacing:-0.02em;opacity:0.9;margin:0 auto 64px}.signup-13lP2{display:block;padding:11px 20px;font-weight:500;margin:-37px auto 47px}@media (min-width: 600px){.signup-13lP2{display:none}}\n", ""]);
// Exports
exports.locals = {
	"title": "title-EhWOQ",
	"body": "body-gq8k5",
	"signup": "signup-13lP2"
};
module.exports = exports;
