import React from 'react';
import { object, string, shape } from 'prop-types';
import styledModule from 'helpers/styledModule';
import resetStyles from 'higher-order-components/resetStyles';
import Header from './Header';
import Intro from './Intro';
import ItemSection from './ItemSection';
import CSS from './ShareLimit.scss';

const Container = styledModule.div(CSS.container);
const Background = styledModule.div(CSS.background);
const Content = styledModule.div(CSS.content);

const ShareLimit = ({ manifest, sharedBy }) => (
  <Container>
    <Background />
    <Header itemId={manifest.id} sharedBy={sharedBy} />
    <Content>
      <Intro sharedBy={sharedBy} itemId={manifest.id} />
      <ItemSection sharedBy={sharedBy} manifest={manifest} />
    </Content>
  </Container>
);

ShareLimit.propTypes = {
  manifest: object.isRequired,
  sharedBy: shape({
    name: string,
    uuid: string.isRequired,
  }).isRequired,
};

export default resetStyles(ShareLimit);
