import React from 'react';
import { string, shape } from 'prop-types';
import LogoLink from 'components/navigation/LogoLink';
import styledModule from 'helpers/styledModule';
import Link from 'components/Link';
import { INTERNAL_LOCATION } from 'app-constants';
import CSS from './Header.scss';
import SignupCta from '../../SignupCta';

const Container = styledModule.div(CSS.container);
const Buttons = styledModule.div(CSS.buttons);
const Login = styledModule(Link)(CSS.login);
const Signup = styledModule(SignupCta)(CSS.signup);

const Header = ({ itemId, sharedBy }) => (
  <Container>
    <LogoLink className={CSS.logo} width={100} height={26} color="white" />
    <Buttons>
      <Login href={`/getpremium/item/${itemId}/login`}>Inloggen</Login>
      <Signup
        color="white"
        itemId={itemId}
        sharedBy={sharedBy}
        internalLocation={INTERNAL_LOCATION.READ_FOR_FREE_LIMIT_REACHED}
      />
    </Buttons>
  </Container>
);

Header.propTypes = {
  itemId: string.isRequired,
  sharedBy: shape({
    name: string,
    uuid: string.isRequired,
  }).isRequired,
};

export default Header;
