import React from 'react';
import { string, shape } from 'prop-types';
import styledModule from 'helpers/styledModule';
import CheckmarkIconThin from 'components/icons/CheckmarkIconThin';
import { INTERNAL_LOCATION } from 'app-constants';
import SignupCta from '../../SignupCta';
import TellMeMore from '../../TellMeMore';
import CSS from './Outro.scss';

const Container = styledModule.div(CSS.container);
const Content = styledModule.div(CSS.content);
const Title = styledModule.h1(CSS.title);
const Body = styledModule.p(CSS.body);
const USPWrapper = styledModule.div(CSS.uspWrapper);
const USP = styledModule.div(CSS.usp);
const StyledCheckmark = styledModule(CheckmarkIconThin)(CSS.checkmark);
const StyledSignupCta = styledModule(SignupCta)(CSS.button);
const StyleddTellMeMore = styledModule(TellMeMore)(CSS.link);

const Outro = ({ sharedBy, itemId }) => (
  <Container>
    <Content>
      <Title>En, vond je het wat?</Title>
      <Body>
        Met Blendle lees en luister je het allerbeste uit 150 kranten en tijdschriften. Probeer{' '}
        <strong>één maand gratis</strong>.
      </Body>
      <USPWrapper>
        <USP>
          <StyledCheckmark fill="white" />
          {sharedBy.name
            ? `Altijd meepraten met ${sharedBy.name}`
            : 'Altijd meepraten met je vrienden'}
        </USP>
        <USP>
          <StyledCheckmark fill="white" />
          Ook je eigen mening vormen
        </USP>
        <USP>
          <StyledCheckmark fill="white" />
          Opzeggen wanneer je wilt
        </USP>
      </USPWrapper>
      <StyledSignupCta
        color="white"
        itemId={itemId}
        internalLocation={INTERNAL_LOCATION.READ_FOR_FREE}
        sharedBy={sharedBy}
      />
      <StyleddTellMeMore />
    </Content>
  </Container>
);

Outro.propTypes = {
  sharedBy: shape({
    name: string,
    uuid: string.isRequired,
  }).isRequired,
  itemId: string.isRequired,
};

export default Outro;
