import React from 'react';
import { string, object, shape } from 'prop-types';
import { memoize } from 'lodash';
import styledModule from 'helpers/styledModule';
import CheckmarkIconThin from 'components/icons/CheckmarkIconThin';
import { getProviderLogoUrl } from 'helpers/providerHelpers';
import { getTitle, getContentAsText } from 'helpers/manifest';
import { INTERNAL_LOCATION } from 'app-constants';
import CSS from './ItemSection.scss';
import TellMeMore from '../../TellMeMore';
import SignupCta from '../../SignupCta';

const Section = styledModule.section(CSS.section);
const ProviderContainer = styledModule.div(CSS.providerContainer);
const ProviderLogo = styledModule.img(CSS.providerLogo);
const ItemTitle = styledModule.h1(CSS.itemTitle);
const Content = styledModule.div(CSS.content);
const Title = styledModule.h2(CSS.title);
const Body = styledModule.p(CSS.body);
const UspWrapper = styledModule.div(CSS.uspWrapper);
const USP = styledModule.div(CSS.usp);
const StyledCheckmark = styledModule(CheckmarkIconThin)(CSS.checkmark);
const StyledSignupCta = styledModule(SignupCta)(CSS.cta);
const StyledTellMeMore = styledModule(TellMeMore)(CSS.tellMeMore);

const stripTitle = memoize(title => {
  const div = document.createElement('div');
  div.innerHTML = title;

  return div.textContent || div.innerText || title;
});

const ItemSection = ({ sharedBy, manifest }) => {
  const providerId = manifest.provider.id;
  const providerLogoUrl = getProviderLogoUrl(providerId, 'logo.png');
  const itemTitle = getContentAsText(getTitle(manifest.body));

  return (
    <Section>
      <div>
        <ProviderContainer className={`item item-content provider-${providerId}`}>
          <ProviderLogo src={providerLogoUrl} alt={`logo ${providerId}`} />
          <ItemTitle className="item-title">{stripTitle(itemTitle)}</ItemTitle>
        </ProviderContainer>
      </div>
      <Content>
        <Title>Toch lezen?</Title>
        <Body>
          Met Blendle lees en luister je het allerbeste uit 150 kranten en tijdschriften. Als troost
          krijg je één <strong>maand gratis</strong>.{' '}
          {sharedBy.name
            ? `Kun je ${sharedBy.name} mooi de les lezen.`
            : `Kun je je vriend mooi de les lezen.`}
        </Body>
        <UspWrapper>
          <USP>
            <StyledCheckmark />
            Nooit meer ruzie over gratis linkjes
          </USP>
          <USP>
            <StyledCheckmark />
            Je mening vormen over actuele onderwerpen
          </USP>
          <USP>
            <StyledCheckmark />
            Opzeggen wanneer je wilt
          </USP>
        </UspWrapper>
        <StyledSignupCta
          itemId={manifest.id}
          internalLocation={INTERNAL_LOCATION.READ_FOR_FREE_LIMIT_REACHED}
          sharedBy={sharedBy}
        />
        <StyledTellMeMore />
      </Content>
    </Section>
  );
};

ItemSection.propTypes = {
  sharedBy: shape({
    name: string,
    uuid: string.isRequired,
  }).isRequired,
  manifest: object.isRequired,
};

export default ItemSection;
