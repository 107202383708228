import React from 'react';
import { string, shape } from 'prop-types';
import styledModule from 'helpers/styledModule';
import { INTERNAL_LOCATION } from 'app-constants';
import CSS from './Intro.scss';
import SignupCta from '../../SignupCta';

const Title = styledModule.h1(CSS.title);
const Body = styledModule.p(CSS.body);
const Signup = styledModule(SignupCta)(CSS.signup);

const Intro = ({ sharedBy, itemId }) => (
  <div>
    <Title>Tikkeltje ongemakkelijk..</Title>
    <Body>
      {sharedBy.name
        ? `${
            sharedBy.name
          } heeft blijkaar zoveel vrienden dat dit gratis linkje al te vaak gebruikt is. Tijd
      voor een pittig wie-zijn-je-beste-vrienden-gesprek.`
        : 'Populaire vriend heb jij! Iets te veel mensen gingen je al voor: dit gratis linkje is jammer genoeg niet meer geldig.'}
    </Body>
    <Signup
      color="white"
      itemId={itemId}
      internalLocation={INTERNAL_LOCATION.READ_FOR_FREE_LIMIT_REACHED}
      sharedBy={sharedBy}
    />
  </div>
);

Intro.propTypes = {
  sharedBy: shape({
    name: string,
    uuid: string.isRequired,
  }).isRequired,
  itemId: string.isRequired,
};

export default Intro;
