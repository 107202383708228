// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-3PU0S{width:100%;background:rgba(255,14,97,0.05);text-align:center;padding:27px 32px 21px;margin-bottom:31px}@media (min-width: 736px){.container-3PU0S{padding:18px 59px 21px;margin:31px auto;max-width:704px;border-radius:6px}}.title-1rgYi{font-family:\"GT-Walsheim\",sans-serif;color:#ff1060;letter-spacing:-0.05em;font-size:24px}@media (min-width: 736px){.title-1rgYi{font-size:44px}}.body-3iNTq{font-family:\"GT-Walsheim\",sans-serif;color:#595959;opacity:0.9;letter-spacing:-0.02em;font-size:15px;line-height:21px}@media (min-width: 736px){.body-3iNTq{font-size:18px;line-height:22px}}.usp-2T0sO{display:none;font-family:\"GT-Walsheim\",sans-serif;font-size:15px;line-height:15px;letter-spacing:-0.02em;color:#595959;margin-top:19px}@media (min-width: 736px){.usp-2T0sO{display:block}}.checkmark-2JgtV{margin-right:8px}.signup-3nXHm{margin-bottom:8px}@media (min-width: 736px){.signup-3nXHm{margin-top:21px}}\n", ""]);
// Exports
exports.locals = {
	"container": "container-3PU0S",
	"title": "title-1rgYi",
	"body": "body-3iNTq",
	"usp": "usp-2T0sO",
	"checkmark": "checkmark-2JgtV",
	"signup": "signup-3nXHm"
};
module.exports = exports;
