import React from 'react';
import { string, shape } from 'prop-types';
import styledModule from 'helpers/styledModule';
import CheckmarkIconThin from 'components/icons/CheckmarkIconThin';
import { INTERNAL_LOCATION } from 'app-constants';
import CSS from './Intro.scss';
import TellMeMore from '../../TellMeMore';
import SignupCta from '../../SignupCta';

const Container = styledModule.div(CSS.container);
const Title = styledModule.h1(CSS.title);
const Body = styledModule.p(CSS.body);
const USP = styledModule.div(CSS.usp);
const StyledCheckmark = styledModule(CheckmarkIconThin)(CSS.checkmark);
const Signup = styledModule(SignupCta)(CSS.signup);

const Intro = ({ sharedBy, itemId }) => (
  <Container>
    <Title>{sharedBy.name ? `Cadeautje van ${sharedBy.name}` : 'Cadeautje!'}</Title>
    <Body>
      {sharedBy.name
        ? `Jij staat zó hoog in de vriendenlijst van ${
            sharedBy.name
          }, dat je dit artikel gratis mag lezen.
      Graag gedaan. Volgende keer bij jou!`
        : 'Een gegeven paard mag je niet in de bek kijken. Je kunt dit artikel gratis lezen!'}
    </Body>
    <div>
      <USP>
        <StyledCheckmark />
        Nu al fan? Start meteen je <strong>gratis maand</strong> Blendle
      </USP>
      <Signup
        itemId={itemId}
        internalLocation={INTERNAL_LOCATION.READ_FOR_FREE}
        sharedBy={sharedBy}
      >
        Start nu je gratis maand
      </Signup>
      <TellMeMore />
    </div>
  </Container>
);

Intro.propTypes = {
  sharedBy: shape({
    name: string,
    uuid: string.isRequired,
  }).isRequired,
  itemId: string.isRequired,
};

export default Intro;
