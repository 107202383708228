// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".button-1LbO9{width:100%;font-weight:500;max-width:214px;padding:10px 20px}@media (min-width: 600px){.button-1LbO9{max-width:250px;padding:11px 20px}}\n", ""]);
// Exports
exports.locals = {
	"button": "button-1LbO9"
};
module.exports = exports;
