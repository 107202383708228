import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { placeContent } from 'helpers/itemContent';
import { getSubItems } from 'selectors/content';
import { providerHasImageGrid, prefillSelector } from 'selectors/providers';
import contentRenderer from '../../../../item/helpers/contentRenderer';
import CSS from './style.scss';

const sortAndPlaceContent = providerId => content =>
  placeContent({
    providerId,
    content,
    streamers: content.streamers || [],
    containerWidth: 640,
    useSmartCrop: true,
    imageGridEnabled: prefillSelector(providerHasImageGrid)(providerId),
  });

class ContentBody extends PureComponent {
  static propTypes = {
    content: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.enhanceLinks();
  }

  enhanceLinks() {
    const element = ReactDOM.findDOMNode(this);
    Array.from(element.querySelectorAll('a')).forEach(anchor => {
      anchor.target = '_blank';
      anchor.rel = 'noopener';
    });
  }

  render() {
    const { content } = this.props;
    const sortAndPlaceProviderContent = sortAndPlaceContent(content.provider.id);
    const sortedItemContent = sortAndPlaceProviderContent(content);
    const renderedItem = contentRenderer(sortedItemContent);
    const renderedSubItems = getSubItems(content)
      .map(sortAndPlaceProviderContent)
      .map(contentRenderer)
      .map((subItem, i) => (
        <div className="subitem" key={`${subItem}-${i}`}>
          {subItem}
        </div>
      ));

    return (
      <article className={`item item-content provider-${content.provider.id} ${CSS.contentBody}`}>
        {renderedItem}
        {renderedSubItems}
      </article>
    );
  }
}

export default ContentBody;
