// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-1saKu{position:relative;min-height:100vh;text-align:center;background:white;display:flex;flex-direction:column;overflow:hidden}.background-1mwJ1{background:linear-gradient(180deg, #ff0e61 11.13%, #ff6255 100%);height:200px;width:100%;position:absolute}.background-1mwJ1:after{content:'';width:100%;height:400%;position:absolute;background:inherit;top:0;left:0;right:0;transform-origin:200%;transform:skewY(6deg)}.content-11KSx{z-index:100;padding:0 32px}\n", ""]);
// Exports
exports.locals = {
	"container": "container-1saKu",
	"background": "background-1mwJ1",
	"content": "content-11KSx"
};
module.exports = exports;
