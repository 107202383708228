// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".itemMeta-tvT32{padding-top:0}.providerLogo-2w-lq{width:100%;height:55px;display:flex;align-items:center;justify-content:center}.providerLogo-2w-lq img{max-height:50%;max-width:45%}@media screen and (min-width: 600px){.providerLogo-2w-lq img{max-width:180px}}\n", ""]);
// Exports
exports.locals = {
	"itemMeta": "itemMeta-tvT32",
	"providerLogo": "providerLogo-2w-lq"
};
module.exports = exports;
