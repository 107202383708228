// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-38Zxm{background:linear-gradient(106.81deg, #ff0e61 0%, #ff6255 100%)}.content-3oS0X{max-width:600px;width:100%;margin:0 auto;text-align:center;padding:67px 16px}@media (min-width: 600px){.content-3oS0X{padding:87px 16px}}.title-2dsjW{font-family:\"GT-Walsheim\",sans-serif;color:#fff;letter-spacing:-0.05em;font-size:30px;margin-bottom:6px}@media (min-width: 600px){.title-2dsjW{font-size:44px}}.body-1GiD8{font-family:\"GT-Walsheim\",sans-serif;color:#fff;opacity:0.9;letter-spacing:-0.02em;font-size:15px}@media (min-width: 600px){.body-1GiD8{font-size:20px}}.uspWrapper-vaH1F{text-align:left;width:205px;margin:23px auto 20px}.usp-2hxN7{font-family:\"GT-Walsheim\",sans-serif;line-height:15px;font-size:15px;letter-spacing:-0.02em;color:#fff;margin-bottom:8px}.checkmark-1YgBT{margin-right:8px}.button-jsba5{margin-top:11px;margin-bottom:10px}.link-26zb7{color:#fff}\n", ""]);
// Exports
exports.locals = {
	"container": "container-38Zxm",
	"content": "content-3oS0X",
	"title": "title-2dsjW",
	"body": "body-1GiD8",
	"uspWrapper": "uspWrapper-vaH1F",
	"usp": "usp-2hxN7",
	"checkmark": "checkmark-1YgBT",
	"button": "button-jsba5",
	"link": "link-26zb7"
};
module.exports = exports;
