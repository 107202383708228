import React from 'react';
import Link from 'components/Link';
import { string, shape } from 'prop-types';
import Analytics from 'instances/analytics';
import styledModule from 'helpers/styledModule';
import { Button } from '@blendle/lego';
import { convertToLandingProjectURL } from 'libs/middleware/routerRedirectToLanding';
import CSS from './SignupCta.scss';

const StyledButton = styledModule(Button)(CSS.button);

function onClick(itemId, uuid) {
  Analytics.track('Share Free Article: Clicked Signup CTA', {
    item_uid: itemId,
    referrer_user_uuid: uuid,
  });
}

const SignupCta = ({ color, className, itemId, internalLocation, sharedBy }) => {
  const { uuid } = sharedBy;

  return (
    <Link
      onClick={() => onClick(itemId, uuid)}
      href={convertToLandingProjectURL('/g/welcome', {
        itemId,
        internalLocation,
      })}
    >
      <StyledButton color={color} className={className}>
        Start nu je gratis maand
      </StyledButton>
    </Link>
  );
};

SignupCta.propTypes = {
  itemId: string.isRequired,
  internalLocation: string.isRequired,
  color: string,
  className: string,
  sharedBy: shape({
    name: string,
    uuid: string.isRequired,
  }).isRequired,
};

SignupCta.defaultProps = {
  color: 'razzmatazz',
  className: '',
};

export default SignupCta;
